/*global  ajax_object*/

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function initSlick() {
  if ($(window).width() <= 640) {
    if (!$('.activities-list').hasClass('slick-initialized')) {
      $('.activities-list').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.slider-prev'),
        nextArrow: $('.slider-next'),
      });
    }
  } else {
    if ($('.activities-list').hasClass('slick-initialized')) {
      $('.activities-list').slick('unslick');
    }
  }

  if ($(window).width() <= 640) {
    if (!$('.hotels-list').hasClass('slick-initialized')) {
      $('.hotels-list').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.hotels-prev'),
        nextArrow: $('.hotels-next'),
      });
    }
  } else {
    if ($('.hotels-list').hasClass('slick-initialized')) {
      $('.hotels-list').slick('unslick');
    }
  }
}

// function initActivityLevelSlick() {
// var $carousel = document.querySelector('.activity-level-post-list');
// console.log($carousel);

// if (!$('.activity-level-post-list').hasClass('slick-initialized')) {
//   $('.activity-level-post-list').slick({
//     infinite: true,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     arrows: true,
//   });
// }
// $carousel.slick({
//   infinite: true,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   arrows: true,
// });
// }

function adjustTopBarHeight() {
  var vh = $(window).height(); // Висота вьюпорта
  var topBarTopHeight = $('.top-bar__top').outerHeight(); // Висота елемента .top-bar__top з урахуванням відступів
  var searchHeight = $('.search').outerHeight(); // Висота елемента .search з урахуванням відступів

  var newHeight = vh - topBarTopHeight - searchHeight; // Обчислення нової висоти

  $('.header-menu.menu').css('height', newHeight + 'px'); // Встановлення нової висоти для .top-bar
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  var mainMenu = document.querySelector('.top-bar__top');
  // var mainMenuHeight = mainMenu.offsetHeight;
  // $('.top-bar').css('height', 100 + 'vh' - mainMenuHeight);
  adjustTopBarHeight();

  $('.has-dropdown a').click(function () {
    $('#menu-main-menu').addClass('show-submenu');
  });

  $('.header-menu ').on('hide.zf.dropdownMenu', function () {
    $('#menu-main-menu').removeClass('show-submenu');
  });

  $('.search__input').on('focus', function () {
    $('.search__submit').addClass('focused');
  });
  // $('.search__input').on('blur', function () {
  //   $('.search__submit').removeClass('focused');
  // });

  var topBar = document.querySelector('.top-bar');
  var topBarWidth = topBar.offsetWidth;
  $('.top-bar').css('left', '-' + (topBarWidth + 6) + 'px');
  $('.custom-menu-icon').click(function () {
    // console.log(width);
    $('.top-bar').css('left', 0);
    $('.top-bar').css('opacity', '1');
  });
  $('.close-button').click(function () {
    $('.top-bar').css('left', '-' + (topBarWidth + 6) + 'px');
    $(mainMenu).removeClass('show-submenu');
  });

  // Initialize on document ready
  initSlick();

  $('.explore-slider__bg').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    // infinite: false,
    arrows: false,
    fade: true,
    asNavFor: '.explore-slider__nav',
  });
  $('.explore-slider__nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    // infinite: false,
    asNavFor: '.explore-slider__bg',
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    prevArrow: $('.slider-prev'),
    nextArrow: $('.slider-next'),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  var categoriesArray = [];
  $('.list-heading').hide();

  $('.category-list__item').on('click', function () {
    $('.list-heading').show();
    var selectedCategories = $(this).data('category');
    // console.log(selectedCategories);
    // console.log(categoriesHeadingArray);
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'filter_activities_heading',
        headingCategory: selectedCategories,
        // paged: 1,
      },
      success: function (response) {
        $('.list-heading').html(response);
      },
      error: function (errorThrown) {
        console.log(errorThrown);
      },
    });
  });

  $('.category-list__item').on('click', function () {
    $('.category-list-wrap').removeClass('category-list-wrap__open');

    $('.category-list__item').removeClass('active-category');
    $(this).addClass('active-category');

    var selectedCategories = $(this).data('category');

    // if ($(this).hasClass('active-category')) {
    //   // Add selected category to categoriesArray if it has the 'active-category' class
    //   if (!categoriesArray.includes(selectedCategories)) {
    //     categoriesArray.push(selectedCategories);
    //   }
    // } else {
    //   // Remove selected category from categoriesArray if the 'active-category' class is removed
    //   var index = categoriesArray.indexOf(selectedCategories);
    //   if (index !== -1) {
    //     categoriesArray.splice(index, 1);
    //   }
    // }
    if ($(this).hasClass('active-category')) {
      categoriesArray = [selectedCategories];
    }

    // console.log(categoriesArray);
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'filter_activities',
        category: selectedCategories,
        paged: 1,
      },
      success: function (response) {
        $('.activities-list').html(response);
      },
      error: function (errorThrown) {
        console.log(errorThrown);
      },
    });
  });

  $(document).on('click', '.clear-selection', function () {
    $('.category-list__item').removeClass('active-category');
    // console.log('test');
    $('.list-heading').hide();
    $('.activities-list').hide();
    categoriesArray = [];
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'clear_filter_activities',
      },
      success: function (response) {
        $('.activities-list').html(response);
      },
      error: function (errorThrown) {
        console.log(errorThrown);
      },
    });
  });

  $(document).on('click', '.activities-more-button', function () {
    let page = $(this).data('paged');
    let currButton = $('.more-button-wrap');
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'filter_activities',
        category: categoriesArray,
        paged: page,
      },
      beforeSend() {},
      success: function (response) {
        currButton.remove();
        $('.activities-list').append(response);
      },
    });
  });

  $('.itineraries-slider').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: $('.itineraries-prev'),
    nextArrow: $('.itineraries-next'),
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  $('.posts-slider-section').each(function () {
    var $this = $(this);
    var postsSliderSectionData = $this.data('posts-number');
    // console.log('test');
    $this.find('.posts-list').slick({
      infinite: true,
      slidesToShow: postsSliderSectionData,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: $this.find('.posts-slider-prev'),
      nextArrow: $this.find('.posts-slider-next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // {
        //   breakpoint: 640,
        //   settings: {
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //   },
        // },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    });
  });

  $('.activity-level-post-list').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: $('.slider-prev'),
    nextArrow: $('.slider-next'),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  });

  $('.activity-level-category-list__item').on('click', function () {
    // Remove active class from all items and add to the clicked item
    $('.activity-level-category-list__item').removeClass(
      'active-category-level'
    );
    $(this).addClass('active-category-level');

    // Get the data-category value of the clicked item
    var selectedCategoryLevel = $(this).data('category');
    console.log(selectedCategoryLevel);

    // Filter the slides based on the selected category
    $('.activity-level-post-list')
      .slick('slickUnfilter')
      .slick('slickFilter', function () {
        var postCat = $(this)
          .find('.activity-level-post-list__post')
          .data('post-cat');
        return (
          postCat == selectedCategoryLevel || selectedCategoryLevel == 'all'
        );
      });
  });

  /**
   * Calendar events
   */
  $('.mec-has-event').each(function () {
    // Get the data-mec-cell value from the current .mec-has-event element
    var mecCell = $(this).data('mec-cell');

    // Find the element with the ID equal to the data-mec-cell value
    var targetElement = $('#mec-calendar-events-sec-560-' + mecCell);

    if (targetElement.length) {
      // Check if .event-colors div exists, if not create it
      var eventColorsDiv = $(this).find('.event-colors');
      if (eventColorsDiv.length === 0) {
        eventColorsDiv = $('<div class="event-colors"></div>');
        $(this).append(eventColorsDiv);
      }

      // Iterate over each .mec-event-article within the target element
      targetElement.find('.mec-event-article').each(function () {
        // Get the background color from the .event-color element
        var bgColor = $(this).find('.event-color').css('background-color');

        if (bgColor) {
          // Create a span element with the background color
          var colorSpan = $('<span></span>')
            .css('background-color', bgColor)
            .addClass('event-color');

          // Append the span element to the .event-colors div
          eventColorsDiv.append(colorSpan);
        }
      });
    }
  });

  $('.itinerary-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
  });

  var itinerarySliderDots = $('.itinerary-slider .slick-dots').outerWidth();
  // console.log(itinerarySliderDots);

  function updateSliderStyles() {
    if ($(window).width() < 1024) {
      $('.itinerary-slider .slick-next').css(
        'right',
        'calc(50% - ' + itinerarySliderDots + 'px)'
      );
      $('.itinerary-slider .slick-prev').css(
        'left',
        'calc(50% - ' + itinerarySliderDots + 'px)'
      );
    } else {
      $('.itinerary-slider .slick-next').css(
        'right',
        'calc(25% - ' + itinerarySliderDots + 'px)'
      );
      $('.itinerary-slider .slick-prev').css(
        'left',
        'calc(75% - ' + itinerarySliderDots + 'px)'
      );
    }
  }

  // Run the function on page load
  updateSliderStyles();

  // Run the function on window resize
  $(window).resize(function () {
    updateSliderStyles();
  });

  jQuery(document).on('gform_post_render', function () {
    jQuery('.gform_wrapper input[type=file]').on('change', function () {
      const fileName = jQuery(this).val().split('\\').pop();
      jQuery('label', jQuery(this).parent().parent()).html(fileName);
    });
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  $('.things-to-do-list__item .item-content-wrap').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
  initSlick();
  adjustTopBarHeight();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
